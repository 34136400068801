@import '../../../config';
@import '../../../mixins';
@import '../../../functions';

.pagebuilder-component-links {
    .link-item {
        @include grid(var(--column-amount), var(--gutter-width));

        @media screen and (max-width: $sm-max) {
            display: flex;
            flex-direction: column-reverse;
            gap: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        & + .link-item {
            padding-top: 30px;
            border-top: 1px solid $light-grey;
        }

        .text-wrapper {
            @include col(7);

            .title {
                margin-bottom: 20px;

                @media screen and (max-width: $xs-max) {
                    margin-bottom: 10px;
                }
            }

            p {
                margin-top: 0;
                margin-bottom: 0;
            }

            .primary-btn {
                margin-top: 50px;

                @media screen and (max-width: $xs-max) {
                    margin-top: 30px;
                }
            }
        }

        .image-frame {
            @include col(8);
            @include col-start(9);
            border-radius: 10px;
            overflow: hidden;

            img {
                background: $lighter-grey;
            }
        }
    }
}
