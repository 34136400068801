@use '../../config' as *;
@use '../../mixins' as *;

.table-of-content {
    .wrapper {
        margin: 5px 0 0;

        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 40px;
        }
    }

    a {
        span {
            &:hover {
                svg {
                    * {
                        stroke: $dark-grey !important;
                        fill: none !important;
                    }
                }
            }
            &.icon-twitter:hover path,
            &.icon-facebook:hover path {
                stroke: none !important;
                fill: $dark-grey !important;
            }
        }

        svg {
            * {
                stroke: $dark-grey;
            }
        }

        .icon-twitter path,
        .icon-facebook path {
            stroke: none;
            fill: $dark-grey;
        }
    }
}
