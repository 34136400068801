@import '../../config';
@import '../../mixins';
@import '../../functions';

#overlay-get-price {
    .title {
        margin-bottom: 0;
    }

    .subtitle {
        margin: 24px 0;
    }

    .extra-informations {
        margin: 24px 0 30px 0;
    }
}
