@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component-text-image {
    &.text-align-left {
        .centered-content {
            .text-wrapper {
                order: 1;
                @include col-start(1);
                @include col(5);
            }

            .image-frame {
                order: 2;
                @include col-start(9);
                @include col(8);
            }

            @media only screen and (max-width: $md-max) {
                .text-wrapper {
                    order: 1;
                    @include col-start(1);
                    @include col(8);
                }

                .image-frame {
                    order: 2;
                    @include col-start(10);
                    @include col(7);
                }
            }

            @media only screen and (max-width: $xs-max) {
                align-items: unset;
                grid-template-rows: 1fr;

                .text-wrapper {
                    margin-top: 20px;
                    order: 2;
                    @include col-start(1);
                    @include col(16);
                }

                .image-frame {
                    order: 1;
                    margin: 0;
                    @include col-start(1);
                    @include col(16);
                }
            }
        }
    }

    &.large-image {
        .centered-content {
            .text-wrapper {
                @include col(3);
            }

            .image-frame {
                @include col(12);
                @include col-start(5);
            }

            @media only screen and (max-width: $md-max) {
                .text-wrapper {
                    @include col(5);
                }

                .image-frame {
                    @include col(10);
                    @include col-start(7);
                }
            }

            @media only screen and (max-width: $xs-max) {
                align-items: unset;
                grid-template-rows: 1fr;

                .text-wrapper {
                    @include col-start(1);
                    @include col(16);
                }

                .image-frame {
                    @include col-start(1);
                    @include col(16);
                }
            }
        }
    }

    &.large-image.text-align-right {
        .centered-content {
            .text-wrapper {
                @include col-start(14);
            }

            .image-frame {
                @include col(12);
                @include col-start(1);
            }

            @media only screen and (max-width: $md-max) {
                .text-wrapper {
                    @include col-start(12);
                }

                .image-frame {
                    @include col(10);
                }
            }

            @media only screen and (max-width: $xs-max) {
                align-items: unset;
                grid-template-rows: 1fr;

                .text-wrapper {
                    @include col-start(1);
                    @include col(16);
                }

                .image-frame {
                    @include col-start(1);
                    @include col(16);
                }
            }
        }
    }

    .centered-content {
        .text-wrapper {
            order: 2;
            @include col-start(11);
            @include col(5);

            .surtitle {
                margin: 0 0 20px;
            }

            h2 {
                margin-top: 0px;
            }

            strong {
                font-weight: inherit;
                color: $red;
            }

            a {
                text-decoration: underline;
            }
        }

        .image-frame {
            order: 1;
            @include col-start(1);
            @include col(8);

            img {
                border-radius: 5px;
            }
        }

        @media only screen and (max-width: $md-max) {
            .text-wrapper {
                @include col-start(10);
                @include col(8);
            }

            .image-frame {
                @include col-start(1);
                @include col(8);
            }
        }

        @media only screen and (max-width: $xs-max) {
            align-items: unset;
            grid-template-rows: 1fr;

            .text-wrapper {
                margin-top: 20px;
                order: 2;
                @include col-start(1);
                @include col(16);
            }

            .image-frame {
                order: 1;
                margin: 0;
                @include col-start(1);
                @include col(16);
            }
        }
    }
}
