@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

span[class^='icon-'] {
    display: inline-block;

    svg {
        position: relative;
        z-index: 1;
        box-sizing: content-box;
        display: block;
        width: 100%;
        margin: 0;
        transform: translateZ(0);
        box-shadow: none;
    }

    &.icon-logo {
        width: 243px;
    }

    &.icon-plus {
        width: 30px;
    }

    &.icon-socials-medias {
        width: 41px;
        svg{
            >path,>rect{
                transition: all 0.3s ease;
            }
        }

        &:not(.icon-instagram){
            &:hover{
                path{
                    fill: $red;
                }
            }
        }
        &.icon-instagram{
            &:hover{
                path{
                    stroke: $red;
                }
            }
        }

        &:hover{

            rect{
                stroke: $red;
            }
        }
    }
}
