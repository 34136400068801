@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

.input-wrapper {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
        @extend .graphic-text;
    }

    .info-msg {
        order: 3;
        margin: 0;
    }

    .error-msg {
        margin: 0;
        font-size: 14px;
        color: $red;
        letter-spacing: 0;
        order: 4;
    }

    button.submit {
        position: absolute;
        right: 8px;
        top: 6px;

        padding: 14px 24px;
    }
}

.checkbox-wrapper {
    margin-bottom: 30px;

    label a {
        font-size: inherit;
    }

    .error-msg {
        margin: 0;
        font-size: 14px;
        color: $red;
        letter-spacing: 0;
        order: 4;
    }
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='password'],
input[type='date'],
textarea,
select {
    @include titillium();
    font-size: vw(20px, false, 16px, 22px);
    line-height: 1.4;
    padding: 12px 20px;
    border-radius: 5px;
    border: 1px solid $light-grey;
    color: $dark-grey;
    background: $white;
    width: 100%;
    transition: border 0.25s ease;
    min-height: 60px;
    height: 60px;
    box-sizing: border-box;
    order: 2;

    &.has-error {
        border: 1px solid $red;

        & + label {
            color: $red;

            & + .error-msg {
                color: $red;
            }
        }
    }

    & + label {
        order: 1;
    }
}

textarea {
    min-height: 150px;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZXZyb24tZG93biI+CjxwYXRoIGlkPSJJY29uIiBkPSJNNy41OTk4NSAxMS4yNjY2TDE1LjA2NjUgMTguNzMzM0wyMi41MzMyIDExLjI2NjYiIHN0cm9rZT0iIzFEMUQxRiIgc3Ryb2tlLXdpZHRoPSIxLjI0NDQ0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8L3N2Zz4K)
        no-repeat 98% 50%;

    option {
        color: $dark-grey;
    }
}

.file-input {
    &.has-error {
        label,
        .file-name {
            border-color: $red;

            svg {
                * {
                    stroke: $red;
                }
            }
            &:not(.button) {
                color: $red;
            }
        }

        .error-msg {
            color: $red;
            display: block;
            margin-top: 0;
        }
    }

    .file-upload {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        &:hover,
        &:focus {
            .icon-upload path:nth-child(2),
            .icon-upload path:nth-child(3) {
                transform: translateY(-3px);
            }
        }

        input[type='file'] {
            &::file-selector-button {
                display: none;
            }
        }

        input[type='file' i] {
            display: none;
        }

        .file-text {
            position: relative;
            display: flex;
            align-items: center;
            min-width: 0;

            @media only screen and (max-width: $xs-max) {
                margin-left: 0;
            }
        }

        label,
        .file-name {
            position: relative;
            left: unset;
            top: unset;
            transform: none;
            white-space: nowrap;

            &.button {
                margin-right: 20px;
            }
        }

        .file-name {
            font-size: vw(14px, false, 12px, 14px);
            line-height: 1.5;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .icon-upload {
            width: 21px;

            svg {
                overflow: visible;
            }

            path {
                transition: transform 0.2s ease-out;
            }
        }

        button {
            &.hide {
                display: none;
            }

            &.remove {
                position: relative;
                height: 100%;
                padding: 10px;
                margin-left: 2px;
                border: none;
                background: none;
                color: $black;
                cursor: pointer;
                z-index: 1;

                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    content: '';
                    height: 13px;
                    width: 1px;
                    background-color: $black;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }

    .file-input-label {
        cursor: pointer;

        &.button {
            top: unset;
            left: unset;
            transform: none;
            position: relative;
            min-width: 210px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s $ease-in-out-quad;

            &:hover {
                background-color: $white;
            }
        }
    }
}

input[type='submit'] {
    @include titillium();
    width: 100%;
    justify-content: center;
}

@media only screen and (max-width: $sm-max) {
    .input-wrapper,
    .checkbox-wrapper {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: $xs-max) {
    .input-wrapper {
        position: relative;

        label {
            font-size: vw(18px, true, 16px, 18px);
        }

        input[type='text'],
        input[type='email'],
        input[type='tel'],
        input[type='password'],
        input[type='date'],
        textarea,
        select {
            font-size: vw(18px, true, 16px, 18px);
        }

        textarea.has-value {
            & + label {
                font-size: vw(14px, true, 12px, 14px);
            }
        }

        .file-upload {
            flex-direction: column;
            row-gap: 10px;
            align-items: flex-start;

            .file-text {
                max-width: 100%;
            }
        }
        .file-input-label {
            &.button {
                width: 100%;
                left: unset;
            }
        }
    }
}
