@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.products-showcase {
    .product {
        @include grid(16, var(--gutter-width));
        @include col-start(1);
        @include col(16);
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }

        .text-wrapper {
            @include col-start(9);
            @include col(6);

            @media only screen and (max-width: $md-max) {
                @include col-start(9);
                @include col(7);
            }

            @media only screen and (max-width: $sm-max) {
                @include col-start(9);
                @include col(8);
            }
            @media only screen and (max-width: $xs-max) {
                @include col-start(1);
                @include col(16);
            }

            .surtitle {
                margin: 0 0 10px;
                text-transform: uppercase;
                line-height: 1;

                @media only screen and (max-width: $xs-max) {
                    margin-top: 10px;
                }
            }

            .title {
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            .tags {
                margin-bottom: 30px;
                pointer-events: none;
            }

            .wysiwyg {
                padding: 24px 0;
                border-top: 1px solid $light-grey;

                li {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 1.4;
                    letter-spacing: 0;

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .image-content {
            @include col-start(1);
            @include col(8);

            @media only screen and (max-width: $xs-max) {
                @include col-start(1);
                @include col(16);
            }
            img {
                border-radius: 10px;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
