@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.categories-sticky-listing {
    position: sticky;
    z-index: 9;
    top: calc(var(--headerHeight) + 10px);
    transition: top 0.2s ease;

    &.scrolled {
        top: 10px;
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            width: 100%;
            margin: 0;

            .wrapper {
                .label {
                    padding: 0 calc(var(--gutter-width) * 2);
                }
                ul {
                    li:first-child {
                        margin-left: calc(var(--gutter-width) * 2);
                    }
                    li:last-child {
                        margin-right: calc(var(--gutter-width) * 2);
                    }
                }
            }
        }
    }

    &[stuck],
    &.stuck {
        .wrapper {
            background: $dark-grey;

            .label {
                opacity: 1;
            }
            ul {
                li {
                    a {
                        border-radius: 10px;

                        &:not(.active) {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .wrapper {
        @include grid(16, var(--gutter-width));
        padding: 12px 20px;
        color: $lighter-grey;
        border-radius: 10px;
        transition: background-color 0.2s ease;
        background-color: transparent;

        .label {
            @include col(4);
            @include col-start(1);
            display: flex;
            flex-direction: column;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        @media only screen and (max-width: $xs-max) {
            padding: 10px 0;
            display: flex;
            flex-direction: column;
        }

        ul {
            @include col(12);
            @include col-start(5);
            display: flex;
            gap: 30px;
            margin: 0;
            padding: 0;
            list-style: none;
            overflow: auto;

            @media only screen and (max-width: $sm-max) {
                gap: 20px;
            }

            @media only screen and (max-width: $xs-max) {
                margin-top: 12px;
                width: 100%;
                gap: 10px;
            }

            li {
                width: 100%;
                margin-bottom: 0;

                a {
                    display: block;
                    width: 100%;
                    padding: 18px 30px;
                    color: $lighter-grey;
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 1;
                    border-left: 2px solid $lighter-grey;

                    @media only screen and (max-width: $sm-max) {
                        padding: 18px 20px;
                        font-size: 18px;
                    }

                    @media only screen and (max-width: $xs-max) {
                        padding: 18px 16px;
                        font-size: 15px;
                        line-height: 0.9;
                    }

                    &.active {
                        color: $black;
                        background-color: $lighter-grey;
                        background-size: 0;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;

                        &:hover,
                        &:focus {
                            background-size: 0;
                        }
                    }
                }
            }
        }
    }
}
