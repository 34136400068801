@import '../../../config';
@import '../../../mixins';
@import '../../../functions';

.pagebuilder-component-big-text {
    .big-text {
        @include col(14);

        @media screen and (max-width: $sm-max) {
            @include col(16);
        }

        strong {
            font-weight: inherit;
            color: $red;
        }
    }
}
