@use '../../config' as *;
@use '../../mixins' as *;

form {
    border:none;

    fieldset{
        border: none;
        padding:0;

        //prevent yellowish background to be present on autofilled inputs
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-text-fill-color: $white;
            transition: background-color 5000000s ease-in-out 0s;
        }
    }
}
