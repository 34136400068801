@import '../../../config';
@import '../../../mixins';
@import '../../../functions';

.pagebuilder-component-installation-maintenance {
    &.single {
        .card {
            position: relative;
            background: $red-secondary;
            color: $white;
            overflow: hidden;
            @include col(16);

            .content {
                @include col(8);
                @include col-start(1);

                position: relative;
                z-index: 2;

                @media only screen and (max-width: $xs-max) {
                    @include col(16);

                    .texts {
                        padding-right: 0;
                    }

                    .extra {
                        margin-top: 30px;
                    }
                }

                h3 {
                    margin-bottom: 40px;
                }

                p {
                    margin: 13px 0;
                }

                span {
                    font-weight: 700;
                }

                .extra {
                    display: block;
                }

                .btn-wrapper {
                    margin-top: vw(50px, false, 30px);
                    position: relative;
                }

                .icon {
                    display: none;
                }

                a {
                    color: $white;
                }
            }

            img {
                display: block;
                position: absolute;
                z-index: 1;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                min-height: 100%;
            }
        }
    }

    .card {
        padding: vw(80px, false, 50px);
        border-radius: 10px;
        background-color: $lighter-grey;
        @include col(8);
        color: $black;
        height: 100%;

        .content {
            position: relative;
            display: flex;
            height: 100%;
            justify-content: space-between;
            flex-direction: column;

            .texts {
                padding-right: 100px;
            }

            .description,
            .price,
            .cta-link {
                font-size: 20px;
                line-height: 1.4;
            }

            .price {
                font-weight: 700;
            }

            .icon {
                position: absolute;
                right: 0;
                top: 0;
                max-width: 78px;

                img {
                    display: block;
                }
            }

            .extra {
                margin-top: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
            }
        }

        img {
            display: none;
        }

        @media only screen and (max-width: $sm-max) {
            @include col(16);
            margin-bottom: 10px;
            height: auto;
            padding: vw(40px, false, 30px);

            .content {
                .texts {
                    padding-right: 80px;
                }

                .extra {
                    margin-top: 30px;
                }

                .icon {
                    max-width: 60px;
                }
            }

            h3 {
                padding-right: 25%;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        &.single {
            .card {
                .content {
                    @include col(16);
                    @include col-start(1);
                }

                img {
                    display: none;
                }
            }
        }
    }
}
