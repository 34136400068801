@use '../../config' as *;
@use '../../mixins' as *;

.primary-btn,
a.primary-btn {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    min-height: 60px;
    padding: 0 30px;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    border: 1px solid $red;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.15;
    text-transform: uppercase;
    color: $white;
    background: $red;
    cursor: pointer;
    transition: color 0.3s ease, background 0.3s ease, opacity 0.3s ease;

    &:hover,
    &:focus,
    &:active,
    &:visited {
        text-decoration: none;
    }

    &:hover,
    &:focus {
        color: $white;
        background: $red-secondary;
    }

    &.alt {
        color: $red;
        background: $white;

        &:hover,
        &:focus {
            color: $white;
            background: $red;
        }
    }

    &.alt-no-color {
        color: $black;
        background: transparent;
        border-color: $light-grey;
        font-weight: 400;
        text-transform: none;

        &:hover,
        &:focus {
            background: $black;
            color: $white;
        }

        &.active {
            border-color: $black;
            pointer-events: none;
        }
    }

    &.small {
        min-height: 30px;
        padding: 0 10px;
        border-radius: 50px;
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.unstyled-btn {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0;
    color: $dark-grey;

    span[class^='icon-'] svg {
        height: 22px;
        width: 22px;
    }
}
