//
// COLORS
// Use http://www.color-blindness.com/color-name-hue/ for color naming
// -----------------------------------------------------------------------------

$black: #000000;
$dark-grey: #1d1d1f;
$grey: #444444;
$light-grey: #dbdbdb;
$lighter-grey: #f6f6f6;
$white: #ffffff;
$red: #e42312;
$red-secondary: #c62214;

//
// MAP-GET() ARRAYS
// -----------------------------------------------------------------------------

//$colors-section-example: (
//    text: $color,
//    border: $color
//);
