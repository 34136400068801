@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.products-slider {
    margin-left: var(--gutter-width) !important;
    margin-right: var(--gutter-width) !important;

    @media only screen and (max-width: $sm-max) {
        margin-left: 0 !important;
        margin-right: 0 !important;

        .section-title {
            margin-left: calc(var(--gutter-width) * 2) !important;
            margin-right: calc(var(--gutter-width) * 2) !important;
        }

        .swiper .swiper-wrapper .card {
            &:first-child {
                padding-left: calc(var(--gutter-width) * 2);
            }
            &:last-child {
                padding-right: calc(var(--gutter-width) * 2);
            }
        }
    }

    .section-title {
        position: relative;
        text-transform: uppercase;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 20px;
        margin-bottom: vw(56px, false, 30px);
        display: flex;
        justify-content: space-between;

        h5,
        .like-h5 {
            margin: 0;
        }

        .navigation {
            position: absolute;
            right: 0;
            bottom: 8px;
            user-select: none;

            .arrow-controls {
                cursor: pointer;
                transition: opacity 0.2s ease;

                &.swiper-button-disabled {
                    opacity: 0;
                    pointer-events: none;
                }

                svg {
                    height: 45px;
                    width: 55px;

                    @media only screen and (max-width: $xs-max) {
                        height: 35px;
                        width: 40px;
                    }
                }
            }

            .previous {
                transform-origin: center;
                transform: rotate(-180deg);
            }
        }
    }

    .swiper {
        display: grid;
        width: 100%;

        .swiper-wrapper {
            display: flex;

            .card {
                box-sizing: content-box;
                height: 100%;
                @include property-calc-col(width, 4, 16);

                img {
                    background: $lighter-grey;
                }

                & + .card {
                    padding-left: var(--gutter-width);

                    @media only screen and (max-width: $xs-max) {
                        padding-left: calc(var(--gutter-width) * 2);
                    }
                }

                @media only screen and (max-width: $sm-max) {
                    @include property-calc-col(width, 8, 16);
                }
                @media only screen and (max-width: $xs-max) {
                    width: 80vw;
                }

                &.intro {
                    position: relative;

                    .image-content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        overflow: hidden;

                        @media only screen and (max-width: $sm-max) {
                            left: calc(var(--gutter-width) * 2);
                            width: calc(100% - (var(--gutter-width) * 2));
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 70%;
                            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
                        }

                        .image-frame {
                            height: 100%;
                        }

                        img {
                            display: block;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .intro-text {
                        position: absolute;
                        bottom: 48px;
                        left: 28px;
                        color: $white;
                        font-weight: 600;

                        div:first-child {
                            text-transform: initial;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
    }
}
