@import '../../../config';
@import '../../../mixins';
@import '../../../functions';

.pagebuilder-component-text {
    .text-wrapper {
        ul,
        ol {
            & + ol,
            & + ul {
                margin-top: 20px;
            }

            li {
                font-size: 20px;
                font-weight: 400;
                line-height: 1.4;
                letter-spacing: 0;
                margin-bottom: 2px;
            }
        }
    }
}
