@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

[class*='pagebuilder-component'] {
    &.article {
        .section-title {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    .section-title {
        text-transform: uppercase;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 20px;
        margin-bottom: vw(56px, false, 30px);
        display: flex;
        justify-content: space-between;

        h5,
        .like-h5 {
            margin: 0;
        }
    }
}
