@use '../config' as *;
@use '../mixins' as *;
@use '../functions' as *;

.page-product {
    .main-section {
        @media screen and (max-width: $md-max) {
            margin-top: 30px !important;
        }

        .main-informations {
            @include grid(16, var(--gutter-width));

            @media screen and (max-width: $sm-max) {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                gap: 20px;
            }

            .images {
                @include col(8);
                position: relative;

                .main-image img:not(.js-changed) {
                    transform: translateX(calc(-1 * var(--gutter-width)));

                    @media screen and (max-width: $sm-max) {
                        transform: translateX(calc(-2 * var(--gutter-width)));
                    }
                }

                .thumbnails {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    right: 10px;
                    display: flex;
                    flex-wrap: nowrap;
                    gap: 10px;
                    max-width: 100%;
                    overflow: auto;

                    @media screen and (max-width: $xs-max) {
                        justify-content: flex-start;
                        padding: 0 10px;
                        left: 0;
                        right: 0;
                    }

                    .thumb {
                        border-radius: 10px;
                        overflow: hidden;
                        width: 100px;
                        height: 100px;
                        min-width: 100px;
                        border: 1px solid transparent;
                        cursor: pointer;
                        background-color: $lighter-grey;
                        transition: border-color 0.2s ease;

                        @media screen and (max-width: $sm-max) {
                            width: 70px;
                            height: 70px;
                            min-width: 70px;
                        }

                        &.active {
                            border-color: $red;
                            pointer-events: none;
                        }

                        &:not(.active):hover,
                        &:not(.active):focus {
                            border-color: $light-grey;
                        }

                        &:first-child {
                            margin-left: auto;
                        }

                        &:last-child {
                            margin-right: auto;
                        }
                    }
                }
            }

            .infos {
                @include col(8);
                background-color: $white;
                padding: 50px 60px;
                border-radius: 10px;

                @media screen and (max-width: $sm-max) {
                    padding: 30px 40px;
                }

                @media screen and (max-width: $xs-max) {
                    padding: 20px;
                }

                .product-name {
                    margin: 20px 0;
                }

                .tags {
                    margin-bottom: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                }

                .availability-info {
                    .cta-link {
                        font-size: inherit;
                    }
                }

                .check-price,
                .check-compat {
                    text-transform: uppercase;
                }

                .check-price {
                    margin-top: 30px;
                    margin-right: 10px;
                }

                .check-compat {
                    margin-top: 15px;
                }

                .price-wrapper {
                    margin: 30px 0;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .price {
                        display: flex;
                        flex-direction: column;

                        .current {
                            color: $red;
                        }
                        .previous {
                            font-size: 20px;
                            color: $dark-grey;
                            opacity: 0.5;
                            text-decoration-line: line-through;
                        }
                    }
                }

                .promotions {
                    font-size: 16px;

                    a {
                        @extend .cta-link;
                        @extend .alt;
                        font-size: inherit !important;
                    }
                }

                .variants-wrapper {
                    .variants {
                        margin-top: 10px;
                        display: flex;
                        gap: 10px;
                    }
                }

                .contact {
                    margin-top: 40px;

                    .contact-title {
                        margin-bottom: 10px;
                    }

                    .contact-option {
                        position: relative;
                        display: block;
                        text-align: left;
                        width: 100%;
                        transition: color 0.2s ease;

                        p {
                            margin: 0;
                        }

                        .icon-arrow {
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            opacity: 0;
                            transition: opacity 0.2s ease;

                            svg {
                                width: 32px;
                                height: 24px;

                                g {
                                    stroke: $red;
                                }
                            }
                        }
                    }

                    button.contact-option,
                    a.contact-option {
                        cursor: pointer;

                        &:hover,
                        &:focus {
                            color: $red;
                            text-decoration: none;

                            .icon-arrow {
                                opacity: 1;
                            }
                        }
                    }

                    .separator {
                        margin: 8px 0;
                        font-size: 14px;
                        line-height: 1.4;
                        display: flex;
                        align-items: center;
                        text-align: center;

                        &::before,
                        &::after {
                            content: '';
                            flex: 1;
                            border-bottom: 1px solid $light-grey;
                        }

                        &:not(:empty)::before {
                            margin-right: 8px;
                        }

                        &:not(:empty)::after {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }

    .faq {
        aside {
            @include col(4);

            .like-h5 {
                margin-top: 10px;
            }
        }

        .product-accordions {
            @include col(12);

            .accordion {
                .content p {
                    font-size: 16px;
                    line-height: 22px;
                }

                &[open] {
                    border-color: $light-grey;
                }
            }
        }

        @media screen and (max-width: $xs-max) {
            aside,
            .product-accordions {
                @include col(16);
            }

            .product-accordions {
                margin-top: 30px;
            }
        }
    }
}
