@use '../../config' as *;
@use '../../mixins' as *;

.image-frame {
    img {
        display: block;
        aspect-ratio: var(--ratio);
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    &.has-parallax-img {
        overflow: hidden;

        img.parallax-img {
            min-height: 100%;
            height: 100%;
            scale: 1.25;
        }
    }
}
