@use '../config' as *;
@use '../mixins' as *;
@use '../functions' as *;

.page-service {
    .content-with-sidebar {
        .centered-content {
            aside {
                @media screen and (max-width: $xs-max) {
                    @include col(16);
                    position: relative;
                    top: 0;
                }
            }

            .content {
                @media screen and (max-width: $xs-max) {
                    @include col(16);
                    @include col-start(1);
                }
            }
        }
    }
}
