@use '../config' as *;
@use '../mixins' as *;
@use '../functions' as *;

.filters-grid-layout {
    #information-bar {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
        min-height: 88px;
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        margin-bottom: 70px;
        padding: 0 var(--gutter-width);

        @media screen and (max-width: $xs-max) {
            margin-bottom: 20px;
            min-height: 65px;
            padding: 0 calc(var(--gutter-width) * 2);
        }

        &.has-filters {
            @media screen and (max-width: $sm-max) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                padding-top: 20px;
                padding-bottom: 20px;
                min-height: auto;
            }
        }

        .results-count {
            font-size: 20px;
            line-height: 1.4;
            flex-shrink: 0;

            @media screen and (max-width: $xs-max) {
                flex-shrink: inherit;
                font-size: 16px;
            }

            strong {
                font-weight: inherit;
                color: $red;
            }
        }

        .active-filters-sorting {
            display: flex;
            align-items: center;
            gap: 30px;
            height: 100%;

            @media screen and (max-width: $xs-max) {
                gap: 20px;

                .active-filters, .cta-link  {
                    display:none!important;
                }
            }

            .active-filters {
                display: flex;
                gap: 5px;
                flex-wrap: nowrap;
                overflow: auto;

                button {
                    pointer-events: none;
                }
            }

            .cta-link {
                flex-shrink: 0;
            }
        }

        .sort-wrapper {
            position: relative;
            padding-left: 30px;
            font-size: 20px;
            line-height: 1.4;
            height: 100%;
            display:flex;
            align-items: center;
            gap: 5px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
                height: 88px;
                width: 1px;
                background-color: $light-grey;
            }

            @media screen and (max-width: $xs-max) {
                padding-left: 20px;
                font-size: 16px;

                &::before {
                    height: 65px;
                }
            }


            button {
                font-size: inherit;
                color: $red;
                cursor: pointer;

                .icon-chevron {
                    transition: transform 0.2s ease;
                }
            }

            .sort-dropdown {
                display: none;
                position: absolute;
                z-index:2;
                top: calc(100% + 27px);
                width: calc(100% + var(--gutter-width));
                right: calc(-1 * var(--gutter-width));
                background-color: $white;
                padding: 5px 20px 5px 30px;
                flex-direction: column;
                border: 1px solid $light-grey;
                gap: 5px;

                @media only screen and (max-width: $xs-max) {
                    top: calc(100% + 19px);
                    width: calc(100% + (var(--gutter-width) * 2));
                    right: calc(-2 * var(--gutter-width));
                }

                input {
                    display: none;

                    + label{
                        opacity: 0.5;
                    }

                    &:checked + label, &:hover + label {
                        opacity: 1;
                        cursor: pointer;
                    }
                    &:checked + label {
                        pointer-events: none;
                    }
                }
            }

            &.is-opened {
                .icon-chevron {
                    transform: rotate(180deg);
                }

                .sort-dropdown {
                    display: flex;
                }
            }
        }

        .filters-wrapper {
            height: 166px;
            display: flex;
            align-items: center;
            gap: 50px;

            @media screen and (max-width: $xs-max) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                height: auto;
                width: 100%;

                .input-wrapper {
                    margin-bottom: 0;
                }
            }

            select {
                min-width: min(410px, 22vw);
                @media screen and (max-width: $xs-max) {
                    width: 100%;
                }
            }

            .search-wrapper {
                display: flex;
                gap: 8px;

                @media screen and (max-width: $xs-max) {
                    width: 100%;
                }

                input[type='text'] {
                    min-width: min(410px, 37vw);
                }

                .search-btn {
                    width: 64px;
                    height: 60px;
                    align-self: flex-end;
                    margin-bottom: 30px;
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9InNlYXJjaCI+CjxwYXRoIGlkPSJJY29uIiBkPSJNMjEgMjFMMTYuNjUgMTYuNjVNMTkgMTFDMTkgMTUuNDE4MyAxNS40MTgzIDE5IDExIDE5QzYuNTgxNzIgMTkgMyAxNS40MTgzIDMgMTFDMyA2LjU4MTcyIDYuNTgxNzIgMyAxMSAzQzE1LjQxODMgMyAxOSA2LjU4MTcyIDE5IDExWiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8L3N2Zz4K)
                        no-repeat 50% 50% $red;

                    @media screen and (max-width: $xs-max) {
                        margin-top: 25px;
                    }

                    @media screen and (max-width: $sm-max) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    & > .section:not(.no-margin) {
        margin-top: 0;
    }

    .main-content {
        @include grid(16, var(--gutter-width));

        @media screen and (max-width: $xs-max) {
            display: block;
        }

        #filters {
            @include col(4);

            @media screen and (max-width: $sm-max) {
                @include col(5);
            }
            @media screen and (max-width: $xs-max) {
                details {
                    .active-filters{
                        display: block!important;
                        margin-top: 10px;

                        button{
                            margin-bottom: 5px;
                        }
                    }

                    .cta-link {
                        font-size: 16px;
                        display: inline-block!important;
                    }
                }
            }

            details {
                padding: 20px 0;
                border-bottom: 1px solid $light-grey;

                .active-filters, .cta-link{
                    display:none;
                }

                &.wrapper-details[open]{
                    border-bottom: none;
                }

                &:first-child {
                    padding-top: 0;
                }

                summary {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1.5;
                }

                ul {
                    margin: 16px 0 0;
                    padding: 0;
                }
            }
        }

        #results {
            @include col(12);
            @include col-start(5);
            @include grid(12, var(--gutter-width));
            row-gap: 56px;
            transition: opacity 0.1s ease, filter 0.1s ease;

            @media screen and (max-width: $sm-max) {
                @include col(11);
                @include col-start(6);
                @include grid(12, var(--gutter-width));
                row-gap: 40px;
            }

            @media screen and (max-width: $xs-max) {
                margin-top: 40px;
            }

            &:first-child {
                @include col(16);
                @include col-start(1);
            }

            &.htmx-request {
                opacity: 0.6;
                filter: blur(3px);
            }

            .card {
                @include col(4);
                margin-bottom: 0;

                @media screen and (max-width: $sm-max) {
                    @include col(6);
                }

                @media screen and (max-width: 540px) {
                    @include col(12);
                }
            }

            .message {
                text-align: center;
            }
        }
    }

    #pagination {
        border-top: 1px solid $light-grey;
        margin-top: 120px;
        padding-top: 20px;

        .wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 24px;

            button {
                font-size: 20px;
                font-weight: 400;
                line-height: 1.2;
                cursor: pointer;

                &:disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }

                .icon-arrow {
                    width: 15px;

                    svg {
                        height: 100%;
                    }

                    path {
                        stroke: $black;
                    }
                }

                &:first-child {
                    .icon-arrow {
                        transform: rotate(180deg);
                    }
                }
            }

            .numbers {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 2px;

                .active {
                    pointer-events: none;
                }

                a,
                span {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 1.2;
                    padding: 12px;
                    width: 40px;
                    height: 40px;
                    display: inline-flex;
                    align-items: center;
                }
            }
        }
    }
}
