@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.product.card {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: $xs-max) {
        gap: 10px;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        .image-content img {
            transform: scale(1.1);
        }
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;

        @media screen and (max-width: $xs-max) {
            gap: 20px;
        }

        .text-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                margin-bottom: 20px;

                @media screen and (max-width: $xs-max) {
                    margin-bottom: 10px;
                }
            }

            .price {
                margin-bottom: 10px;
                font-size: 24px;
                line-height: 23px;
                font-weight: 600;
                color: $red-secondary;

                .previous {
                    color: $dark-grey;
                    text-decoration-line: line-through;
                    margin-left: 10px;
                    opacity: 0.5;
                }
            }
        }
    }

    .image-content {
        overflow: hidden;

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 0.2s ease;

            background: $lighter-grey;
        }
    }
}
