@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

footer.section {
    background: $dark-grey;
    color: $white;

    .help-center {
        background: $red-secondary;
        position: relative;
        @include col(16);
        @include col-start(1);

        padding: 75px 0;

        &:before {
            content: ' ';
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .wrapper {
            display: flex;
            gap: var(--gutter-width);

            @media screen and (max-width: $xs-max) {
                flex-direction: column;
                gap: 20px;
            }

            .col {
                position: relative;
                border-top: 1px solid $white;
                padding-top: 20px;
                width: calc((100% - (var(--gutter-width) * 2)) / 3);
                display: flex;
                flex-direction: column;
                gap: 8px;

                @media screen and (max-width: $xs-max) {
                    width: 100%;
                }

                .like-h4,
                p {
                    margin: 0;
                }

                .like-h4 {
                    max-width: 50%;

                    @media screen and (max-width: $sm-max) {
                        max-width: 70%;
                    }
                }

                .icon {
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 28px;
                    width: 35px;
                    height: 35px;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    .footer-content {
        padding: vw(110px, false, 40px) 0;
        @include grid(16, var(--gutter-width));

        .logo {
            @include col(3);
            @include col-start(1);

            span {
                max-width: 100%;
            }
        }

        .content {
            margin-top: -7px;
            @include col(7);
            @include col-start(5);

            p.footer-motto {
                line-height: 0.95;
                margin-bottom: vw(54px, false, 30px);
                letter-spacing: -1.5px;
                text-transform: uppercase;
            }

            ul {
                display: flex;
                list-style: none;
                padding: 0;
                margin: 0;
                gap: 5px 20px;
                flex-wrap: wrap;
            }

            a {
                color: $white;
                font-size: 17px;
                line-height: 1;
            }
        }

        .socials {
            @include col(4);
            @include col-start(12);

            .surtitle + p {
                margin-top: 30px;
            }

            form {
                margin: 30px 0;

                a {
                    color: $white;
                    font-weight: bold;
                }

                .recaptcha {
                    font-size: vw(12px, false, 12px);
                    margin-top: 0;
                }

                .success-message,
                .error-message {
                    margin-top: 0;
                }

                .submit-wrapper {
                    position: relative;

                    input {
                        padding-right: 60px;
                        color: $white;
                        background-color: $grey;
                        border-radius: 8px;
                        border: none;

                        &.has-value {
                            padding-bottom: 0;

                            & + label {
                                padding: 0 0 28px 0;
                                font-size: vw(14px, false, 12px, 14px);
                                line-height: 140%;
                            }
                        }
                    }

                    label {
                        @extend p;
                        position: absolute;
                        left: 20px;
                        top: 30px;
                        transform: translateY(-50%);
                        margin: 0;
                        padding: 0;
                        text-transform: none;
                        transition: all 0.3s $ease-in-out-quad;
                    }

                    button {
                        background: none;
                        outline: none;
                        border: none;

                        .icon-arrow {
                            cursor: pointer;
                            transition: transform 0.2s ease;
                        }

                        &:hover,
                        &:focus {
                            .icon-arrow {
                                transform: translateX(10px);
                            }
                        }
                    }
                }

                .input-wrapper:focus {
                    background-color: red;
                }

                .form-actions {
                    position: absolute;
                    top: 30px;
                    right: 5px;
                    transform: translateY(-50%);

                    button {
                        padding: 15px;
                    }

                    .icon-plus svg,
                    .icon-arrow svg {
                        height: 30px;

                        g {
                            stroke: $white;
                        }
                    }

                    .icon-arrow {
                        display: none;
                    }

                    &.has-value {
                        .icon-arrow {
                            display: block;
                        }
                        .icon-plus {
                            display: none;
                        }
                    }
                }
            }

            .socials-icons {
                display: flex;
                gap: 16px;
            }
        }

        @media only screen and (max-width: $md-max) {
            .socials {
                @include col(5);
                @include col-start(12);
            }
        }

        @media only screen and (max-width: $sm-max) {
            .logo {
                @include col(8);
                @include col-start(1);
                margin-bottom: 40px;
            }

            .content {
                margin-top: 0;
                @include col(8);
                @include col-start(1);
            }
            .socials {
                @include col(8);
                @include col-start(9);

                .surtitle + p {
                    margin-top: 15px;
                }
            }
        }

        @media only screen and (max-width: $xs-max) {
            .logo {
                @include col(16);
                @include col-start(1);
                margin-bottom: 30px;
            }

            .content {
                @include col(16);
                @include col-start(1);
            }
            .socials {
                margin-top: 40px;
                @include col(16);
                @include col-start(1);

                form {
                    margin: 20px 0;
                }
            }
        }
    }

    .extra {
        @include grid(16, var(--gutter-width));
        border-top: 1px solid $white;
        padding: 30px 0;

        .copyright {
            @include col(6);
            @include col-start(5);
            font-size: 17px;
            line-height: 1;
        }

        .links {
            @include col(5);
            @include col-start(12);
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            gap: 20px;
            flex-wrap: wrap;

            a {
                color: $white;
                font-size: 17px;
                line-height: 1;
            }
        }

        @media only screen and (max-width: $sm-max) {
            .copyright {
                @include col(8);
                @include col-start(1);
            }

            .links {
                @include col(8);
                @include col-start(9);
            }
        }
        @media only screen and (max-width: $xs-max) {
            padding-bottom: 80px;

            .copyright {
                @include col(16);
                @include col-start(1);
            }

            .links {
                @include col(16);
                @include col-start(1);
            }
        }
    }
}
