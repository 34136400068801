@import '../../../config';
@import '../../../mixins';
@import '../../../functions';

.pagebuilder-component-images-slideshow {
    --thumbnail-width: 97px;

    @media screen and (max-width: $sm-max) {
        --thumbnail-width: 60px;
    }

    .slideshow-wrapper {
        position: relative;

        .main-image-slideshow {
            .image-frame {
                border-radius: 10px;
                overflow: hidden;

                img {
                    @media screen and (max-width: $xs-max) {
                        aspect-ratio: 1;
                    }
                }
            }
        }

        .navigation-next {
            position: absolute;
            background-color: $red;
            bottom: 20px;
            right: calc(((var(--thumbnail-width) + 11px) * var(--images-count)) + 30px);
            width: var(--thumbnail-width);
            height: var(--thumbnail-width);
            border-radius: 5px;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;

            @media screen and (max-width: $xs-max) {
                right: 15px;
                bottom: 21%;
            }

            .icon-arrow {
                height: 50%;

                svg {
                    height: 100%;
                    width: auto;

                    g {
                        stroke: $white;
                    }
                }
            }
        }

        .thumbnails {
            position: absolute;
            bottom: 20px;
            right: 17px;
            width: auto;
            user-select: none;

            @media screen and (max-width: $xs-max) {
                position: relative;
                width: 100%;
                bottom: -20px;
                right: 0;
            }

            .swiper-slide {
                width: var(--thumbnail-width);
                height: auto;
                aspect-ratio: 1;
                border-radius: 5px;
                border: 2px solid transparent;
                overflow: hidden;
                user-select: none;

                &.swiper-slide-thumb-active {
                    border-color: $white;

                    @media screen and (max-width: $xs-max) {
                        border-color: var(--text-color);
                    }
                }
            }
        }
    }
}
