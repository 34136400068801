@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.testimonials-listing {
    .centered-content {
        .section-title {
            text-transform: uppercase;
            border-bottom: 1px solid $light-grey;
            padding-bottom: 20px;
            margin-bottom: vw(56px, false, 30px);
            display: flex;
            justify-content: space-between;

            h5,
            .like-h5 {
                margin: 0;
            }
        }
    }

    .testimonial {
        @include col(4);

        @media only screen and (max-width: $sm-max) {
            @include col(8);
            margin-bottom: 50px;
        }

        @media only screen and (max-width: $xs-max) {
            @include col(16);
        }
    }
}
