@use '../../config' as *;
@use '../../mixins' as *;

:root {
    --headerHeight: 80px;

    @media screen and (max-width: $sm-max) {
        --headerHeight: 70px;
    }
}

.header-content {
    --background-color: transparent;
    --text-color: #{$white};
    @include grid(16, var(--gutter-width));
    position: fixed;
    top: 0;
    padding: 0 var(--gutter-width);
    min-height: var(--headerHeight);
    width: 100%;
    z-index: 10;
    transition: transform 0.2s ease, background-color 0.2s ease;
    background-color: var(--background-color);

    @media screen and (max-width: $sm-max) {
        padding-right: 0;
        padding-left: calc(var(--gutter-width) * 2);
    }

    &.scrolled {
        transform: translateY(-100%);
    }


    &.dark {
        --background-color: #{$dark-grey};
        --text-color: #{$white};
    }


    &.light,
    &.is-opened,
    &.mobile-menu-visible {
        --background-color: #{$white};
        --text-color: #{$dark-grey};

        .nav-submenu:not(.visible) {
            transition-delay: 0.1s !important;
        }
    }

    ul {
        list-style-type: none;

        &,
        li {
            margin: 0;
            padding: 0;
        }
    }

    .logo-link {
        position: relative;
        @include col(3);
        margin: auto 0;
        z-index: 20;

        @media screen and (max-width: $md-max) {
            @include col(2);
        }

        .icon-logo {
            width: 224px;

            @media screen and (max-width: $md-max) {
                width: 167px;
            }

            svg path:last-child {
                fill: var(--text-color);
            }
        }
    }

    nav.main-nav {
        @include col(9);
        @include col-start(5);
        margin: auto 0;

        > ul {
            display: flex;
            gap: 20px;

            li.top-nav-item {
                .nav-link {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 1.4;
                    white-space: nowrap;
                    color: var(--text-color);

                    &.active {
                        font-weight: 600;
                    }

                    .icon-chevron {
                        display: none;
                    }
                }

                .nav-submenu {
                    @include grid(16, var(--gutter-width));
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background-color: $white;
                    display: grid;
                    padding: 70px var(--gutter-width) calc(70px - 28px);
                    border-bottom: 1px solid $lighter-grey;
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity 0.2s ease;

                    &.visible {
                        opacity: 1;
                        pointer-events: all;
                    }

                    .mobile-back-btn {
                        display: none;
                    }

                    .submenu-column {
                        @include col(5);

                        &:nth-child(2) {
                            padding-bottom: 70px;
                        }

                        .submenu-title {
                            font-size: 22px;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;
                            color: $red;
                            margin-bottom: 20px;
                        }

                        li a {
                            font-size: 28px;
                            font-weight: 600;
                            line-height: normal;

                            &:hover,
                            &:focus {
                                font-weight: 700;
                                text-decoration: none;
                            }
                        }

                        li + li {
                            margin-top: 10px;
                        }

                        &.column-image {
                            @include col(6);

                            &:nth-child(3) {
                                @include col-start(11);
                            }

                            img {
                                display: block;
                                object-fit: cover;
                                width: 100%;
                                height: auto;
                                aspect-ratio: 574 / 477;
                                border-radius: 10px;
                            }
                        }
                    }

                    .cta-link {
                        position: relative;
                        bottom: 28px;
                        left: 0;
                        margin-right: auto;
                    }
                }
            }
        }

        .mobile-extra-links,
        .mobile-extra-social,
        .language-switcher {
            display: none;
        }
    }

    & > ul:last-child {
        @include col(3);
        margin: auto 0;
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: $sm-max) {
            @include col(14);
        }

        a,
        button {
            color: var(--text-color);
            font-size: 20px;
            font-weight: 400;
            line-height: 1.4;

            @media screen and (max-width: $sm-max) {
                font-size: 17px;
                font-weight: 600;
                line-height: 1;
            }

            .icon-cart svg path,
            .icon-chevron svg path {
                stroke: var(--text-color);
            }
        }

        .cart-wrapper {
            position: relative;
            z-index: 20;
        }

        .language-switcher {
            button {
                cursor: pointer;
            }

            @media screen and (max-width: $sm-max) {
                display: none;
            }
        }

        .burger-button-wrapper {
            display: none;

            @media screen and (max-width: $sm-max) {
                display: flex;
            }

            .burger-btn {
                position: relative;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 8px;
                width: 70px;
                height: 70px;
                background-color: $red;
                border-bottom-left-radius: 10px;
                z-index: 20;
                cursor: pointer;

                span {
                    width: 27px;
                    height: 1px;
                    background-color: $white;
                    transform-origin: left;
                    transition: transform 0.2s ease;
                }
            }
        }
    }

    @media screen and (max-width: $sm-max) {
        &.mobile-menu-visible {
            nav.main-nav {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh; /* fallback */
                height: 100dvh;
                background-color: $white;
                padding: 100px 20px 25px;
                z-index: 15;

                > ul {
                    flex-direction: column;
                    gap: 20px;

                    li.top-nav-item {
                        .nav-link {
                            display: flex;
                            justify-content: space-between;
                            gap: 5px;
                            position: relative;
                            width: 100%;
                            font-size: 28px;
                            line-height: 1;
                            letter-spacing: -0.56px;
                            padding-bottom: 20px;

                            &:not(:last-child) {
                                border-bottom: 1px solid $light-grey;
                            }

                            .icon-chevron {
                                display: block;
                                transform-origin: center;
                                transform: rotate(-90deg);
                            }
                        }

                        .nav-submenu {
                            &.visible {
                                display: flex;
                                flex-direction: column;
                                gap: 30px;
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: calc(100vh - 70px); /* fallback */
                                height: calc(100dvh - 70px);
                                padding: 45px 20px;
                                margin-top: 70px;
                                background-color: $white;
                                overflow: auto;
                                z-index: 30;
                            }

                            .submenu-column {
                                padding-bottom: 0;
                            }

                            .mobile-back-btn {
                                display: inline-flex;

                                .icon-chevron {
                                    transform: rotate(90deg);
                                }
                            }

                            .submenu-title {
                                font-size: 14px;
                                line-height: 15px;
                                text-transform: uppercase;
                                letter-spacing: 0.7px;
                                margin-bottom: 15px;
                            }

                            li a {
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 1.4;
                            }

                            li + li {
                                margin-top: 15px;
                            }
                        }

                        .cta-link {
                            position: relative;
                            bottom: auto;
                            left: auto;
                        }
                    }
                }

                .mobile-extra-links {
                    display: flex;
                    gap: 15px;

                    a {
                        font-size: 20px;
                        line-height: 1.4;
                    }
                }

                .mobile-extra-social {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .socials-icons {
                        filter: brightness(1) invert(1);
                    }

                    .language-switcher {
                        display: block;

                        .unstyled-btn {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0.7px;
                        }
                    }
                }
            }

            .burger-btn {
                span:nth-child(2) {
                    opacity: 0;
                }
                span:nth-child(1) {
                    transform: translateY(-2px) rotate(45deg) scale(1.15);
                }
                span:nth-child(3) {
                    transform: translateY(2px) rotate(-45deg) scale(1.15);
                }
            }
        }
    }
}
