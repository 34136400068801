@use '../../config' as *;
@use '../../mixins' as *;

.language-switcher {
    position: relative;

    &.is-opened {
        .icon-chevron {
            transform: rotate(180deg);
        }

        .language-switcher-dropdown {
            display: flex;
        }
    }

    .icon-chevron {
        transition: transform 0.2s ease;
    }

    .language-switcher-dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: -10px;
        background-color: var(--background-color);
        padding: 5px 30px 5px 10px;
        flex-direction: column;
        gap: 5px;
        border-radius: 8px;

        @media screen and (max-width: $sm-max) {
            top: auto;
            bottom: 100%;
        }

        a:hover,
        a:focus {
            text-decoration: none;
        }
    }
}
