@import '../../../config';
@import '../../../mixins';
@import '../../../functions';

.pagebuilder-component-media-fullwidth {
    position: relative;

    h2 {
        &.title {
            margin-bottom: 50px;
        }
    }

    .media-wrapper {
        width: 100%;
        height: auto;

        .media {
            width: inherit;
            position: relative;
            display: grid;

            &:not(.media-video) {
                aspect-ratio: 1732/727;

                @media only screen and (max-width: $sm-max) {
                    aspect-ratio: 1.6;
                }
            }

            iframe {
                width: 100%;
                height: 100%;
            }

            video {
                width: 100%;
                height: 100%;
            }

            img {
                user-select: none;
                pointer-events: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .video-player,
            .video-poster,
            .image-frame {
                grid-area: 1 / 1;
                border-radius: 10px;
                overflow: hidden;
            }

            .video-player {
                position: relative;
                height: auto;
                aspect-ratio: 16/9;
                overflow: hidden;
                max-width: 100%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .video-poster {
                position: absolute;
                z-index: 2;
                inset: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 10px;
                cursor: pointer;
                transition: opacity 0.75s ease;

                &.inactive {
                    pointer-events: none;
                    opacity: 0;
                }

                &:hover {
                    .play-button {
                        transform: translate(-50%, -50%) scale(1.05);
                        transition: transform ease 250ms;
                    }
                }

                .play-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(1);
                    z-index: 3;
                    transition: transform ease 250ms;

                    display: flex;
                    align-items: center;
                    text-align: center;

                    padding-right: 4px;
                    background: $red;
                    border: none;
                    border-radius: 50%;
                    width: 180px;
                    aspect-ratio: 1;
                    cursor: pointer;

                    svg {
                        margin: 0 auto;
                        width: 34px;
                        height: 42px;
                    }

                    @media only screen and (max-width: $sm-max) {
                        width: 120px;

                        svg {
                            width: 32px;
                        }
                    }

                    @media only screen and (max-width: $xs-max) {
                        width: 90px;

                        svg {
                            width: 22px;
                        }
                    }
                }
            }
        }

        .credit {
            margin-top: 16px;
        }
    }

    &.article .media-wrapper {
        .media {
            aspect-ratio: 1.6;

            .video-poster .play-button {
                width: 120px;

                svg {
                    width: 32px;
                }

                @media only screen and (max-width: $xs-max) {
                    width: 90px;

                    svg {
                        width: 22px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        h2 {
            &.title {
                margin-bottom: 30px;
            }
        }
    }
}
