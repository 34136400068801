@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.documents-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        padding: 0;
        border: 0;
        text-align: left;
    }

    th,
    td,
    td a {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.4;

        @media screen and (max-width: $xs-max) {
            font-size: 18px;
        }
    }

    thead tr th {
        padding-bottom: 16px;

        &:nth-child(2),
        &:nth-child(3) {
            padding-left: 10px;
        }
    }

    tr {
        position: relative;

        td {
            position: relative;
            padding: 24px 0;
            border-bottom: 1px solid $light-grey;
            transition: all 0.1s ease;

            &:nth-child(2),
            &:nth-child(3) {
                padding-left: 10px;
                white-space: nowrap;
            }

            @media screen and (min-width: $md-min) {
                &:last-child {
                    padding-right: 50px;
                }
            }

            @media screen and (max-width: $xs-max) {
                padding: 15px 0;

                &:nth-child(2) {
                    display: none;
                }
            }

            .icon-file {
                width: 28px;
            }

            .icon-arrow {
                position: absolute;
                right: 0;
                opacity: 0;
                height: 28px;
                transition: opacity 0.1s ease;

                svg {
                    height: 100%;
                    width: 32px;

                    path {
                        stroke: $red;
                    }
                }
            }
        }

        &:hover td {
            color: $red;
            border-color: $red;

            a {
                color: $red;

                svg path {
                    stroke: $red;
                }
            }

            @media screen and (min-width: $md-min) {
                .icon-arrow {
                    opacity: 1;
                }
            }
        }
    }
}
