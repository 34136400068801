@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.hero-homepage.dark {
    display: flex;
    align-items: center;
    background: $dark-grey;
    position: relative;
    color: $white;
    height: max(100vh, 650px);

    .image-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.8s ease;

        &.mobile{
            display:none;
        }

        img {
            height: 100%;
        }

        &.image-hidden {
            opacity: 0;
        }

        @media only screen and (max-width: $xs-max) {

            &.desktop{
                display:none;
            }

            &.mobile{
                display:block;
            }

            img {
                max-width: none;
            }
        }
    }

    .progress-wrapper {
        position: absolute;
        bottom: 50px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        progress {
            /* Reset the default appearance */
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            /* Get rid of default border in Firefox. */
            border: none;

            height: 2px;
            width: 100%;
            margin: 0 auto;

            &::-webkit-progress-bar {
                background-color: $white;
            }

            &::-moz-progress-bar {
                background-color: $red;
            }

            &::-webkit-progress-value {
                background-color: $red;
            }
        }

        .items {
            @include grid(16, var(--gutter-width));
            margin-top: 18px;

            @media only screen and (max-width: $sm-max) {
                display: flex;
                justify-content: space-between;
                gap: 10px;
            }

            @media only screen and (max-width: $xs-max) {
                display: none;
            }

            span {
                @include col(2);
                align-self: flex-start;

                &:first-child {
                    @include col-start(5);
                }
            }
        }
    }

    .centered-content {
        height: 100%;
    }

    .wrapper {
        box-sizing: border-box;
        height: 100%;
        padding-top: 120px;
        padding-bottom: 120px;
        @include grid(16, var(--gutter-width));
        align-items: center;

        .content {
            @include col(10);
            @include col-start(5);

            @media only screen and (max-width: $sm-max) {
                @include col(12);
                @include col-start(2);
            }

            @media only screen and (max-width: $xs-max) {
                @include col(16);
                @include col-start(1);
            }
        }

        h1 {
            margin: 0;

            span {
                text-transform: none;
                display: block;
            }

            @media only screen and (max-width: $sm-max) {
                @include col-start(1);
            }
        }

        p {
            margin-top: 30px;
            @include property-calc-col(max-width, 14, 16, var(--gutter-width));

            @media only screen and (max-width: $sm-max) {
                max-width: none;
            }
        }
    }

    .background-filter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    }
}
