@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.shop-by-application {
    .centered-content {
        .section-title {
            text-transform: uppercase;
            border-bottom: 1px solid $light-grey;
            padding-bottom: 20px;
            margin-bottom: vw(56px, false, 30px);
            display: flex;
            justify-content: space-between;

            h5,
            .like-h5 {
                margin: 0;
            }

            strong {
                font-weight: inherit;
                color: $red;
            }
        }
    }

    .applications {
        display: flex;
        gap: var(--gutter-width);
        flex-wrap: wrap;
    }
    .application {
        width: calc((100% - var(--gutter-width) * 2) / 3);

        @media only screen and (max-width: $sm-max) {
            width: calc((100% - var(--gutter-width)) / 2);
            margin-bottom: 10px;
        }

        @media only screen and (max-width: $xs-max) {
            width: 100%;
        }

        .like-h3 {
            display: inline-block;
            margin: 10px 0;
        }

        .image-content {
            display: block;
            aspect-ratio: 556/303;
            border-radius: 10px;
            overflow: hidden;

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: transform 0.2s ease;
            }
        }
    }
}
