@import '../../config';
@import '../../mixins';
@import '../../functions';

#overlay-product-contact {
    .wrapper .content {
        margin: 0;
    }

    .title {
        margin-bottom: 0;
    }

    .subtitle {
        margin: 24px 0 0;
    }

    .contact-infos {
        p {
            margin: 0 0 10px;
        }
    }

    .title + .form-title {
        margin-top: 30px;
    }

    .form-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
    }

    .product-name {
        margin-top: 0;
    }

    .form-fields-columns {
        display: flex;
        gap: 30px;
        margin-top: 10px;

        @media screen and (max-width: $md-max) {
            flex-direction: column;
            gap: 10px;
        }
    }

    button[type='submit'] {
        width: 100%;
        justify-content: center;
    }
}
