@import '../../../config';
@import '../../../mixins';
@import '../../../functions';

.pagebuilder-component-quote {

    &.article{
        .quote {
            @include col-start(1);
            @include col(16);
        }
    }
    .quote {
        @include col(8);
        @include col-start(5);
        border-left: 2px solid $red;
        padding: 8px 0 8px 20px;

        @media screen and (max-width: $sm-max) {
            @include col(10);
            @include col-start(4);
        }

        @media screen and (max-width: $xs-max) {
            @include col(14);
            @include col-start(2);
        }

        blockquote,
        cite {
            display: block;
            margin: 0;
            padding: 0;
            font-style: normal;
        }

        cite {
            margin-top: 32px;
        }
    }
}
