@import '../../config';
@import '../../mixins';
@import '../../functions';

#overlay-product-contact-phone {

    .wrapper{
        min-width: 650px;

        @media only screen and (max-width: $xs-max) {
                min-width: 0;
            }
    }
    .wrapper .content {
        margin: 0;
    }

    .heading{
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $light-grey;
    }

    .title {
        margin-bottom: 0;
    }

    .description {
        margin-bottom: 0;
    }

    ul{
        width: 100%;
        list-style: none;
        margin:0;
        padding:0;
        margin-bottom: 20px;

        &.single{
            margin-bottom: 5px;

            @media only screen and (max-width: $xs-max) {
                margin-bottom: 30px;
                }
        }

        li{
            padding:0;
            margin:0;
            display:flex;

            span{
                &:nth-child(1){
                    width: 166px;
                    margin-right: 20px;
                }
            }

            a{
                margin-left: auto;
                font-weight:bold;
                color: $red;
            }

            @media only screen and (max-width: $xs-max) {
                display: block;
                margin-bottom: 15px;


                ul{
                    columns: 2;

                    li{
                        break-inside: avoid;

                        span{
                            &:nth-child(1) {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                span {
                    display:block;
                    &:nth-child(1) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .product-name {
        margin-top: 0;
    }
}
