@use '../config' as *;
@use '../mixins' as *;
@use '../functions' as *;

.page-contact {
    .content-with-sidebar {
        .centered-content {
            aside {
                @include col(3);

                @media screen and (max-width: $sm-max) {
                    @include col(16);
                    position: relative;
                    top: 0;
                }
            }

            .content {
                @media screen and (max-width: $sm-max) {
                    @include col(16);
                    @include col-start(1);
                }
            }
        }
    }

    #contact-form {
        .form-wrapper {
            display: none;

            &.is-visible {
                display: block;
            }

            & > hr {
                margin-top: 72px;
            }

            .step {
                margin-top: 72px;

                .step-intro {
                    border-bottom: 1px solid $light-grey;
                    margin-bottom: 30px;

                    .like-h4 {
                        margin-bottom: 0;
                        color: $red;
                    }

                    p {
                        margin: 0;
                    }
                }

                #contact-file-link {
                    font-weight: 400;

                    .icon-file {
                        flex-shrink: 0;
                    }

                    .icon-arrow svg {
                        width: 22px;
                        height: 20px;
                        transform: rotate(90deg) translateY(5px);

                        g {
                            stroke: $red;
                        }
                    }
                }

                .form-fields-columns {
                    display: flex;
                    gap: 30px;
                    margin-top: 10px;

                    @media screen and (max-width: $md-max) {
                        flex-direction: column;
                        gap: 10px;
                    }
                }
            }
        }

        input[type='submit'] {
            margin-top: 26px;
            width: auto;
        }
    }
}
