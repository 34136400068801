@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.card.testimonial {
    @include col(4);

    @media only screen and (max-width: $sm-max) {
        @include col(8);
        margin-bottom: 50px;
    }

    @media only screen and (max-width: $xs-max) {
        @include col(16);
    }

    .text-wrapper {
        .surtitle {
            margin: 20px 0 10px;
            text-transform: uppercase;
            font-size: vw(14px, false, 14px);
        }

        .title {
            margin-bottom: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            line-height: 1.25;
            overflow: hidden;
        }

        .tags {
            .tag {
                pointer-events: none;
            }
        }

        .wysiwyg {
            padding: 18px 0 0;

            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                overflow: hidden;
            }
        }
    }

    .image-content {
        overflow: hidden;
        border-radius: 10px;

        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 0.2s ease;
        }
    }

    &:hover {
        .image-content img {
            transform: scale(1.1);
        }
    }
}
