@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.page-product .product-accordions {
    .accordion {
        border: 1px solid $light-grey;
        border-radius: 10px;
        background-color: transparent;
        transition: all 0.1s ease;

        & + .accordion {
            margin-top: 10px;
        }

        &[open] {
            background-color: $white;
            border-color: $white;
        }

        summary {
            padding: 30px 70px 30px 30px;
            font-size: 20px;
            line-height: 28px;

            @media screen and (max-width: $xs-max) {
                padding: 20px 70px 20px 20px;
            }

            &::before,
            &::after {
                right: 30px;

                @media screen and (max-width: $xs-max) {
                    right: 20px;
                }
            }

            &::before {
                height: 34px;
                right: 46px;

                @media screen and (max-width: $xs-max) {
                    right: 36px;
                }
            }

            &::after {
                width: 34px;
            }
        }

        .content {
            padding: 0 30px 30px 30px;

            @media screen and (max-width: $xs-max) {
                padding: 0 20px 20px 20px;
            }

            &.overview-content {
                @include grid(16, var(--gutter-width));

                @media screen and (max-width: $xs-max) {
                    display: flex;
                    flex-direction: column-reverse;
                    gap: 30px;
                }

                & > div:first-child {
                    @include col(8);
                }

                .image-frame {
                    @include col(8);
                }
            }

            &.tech-specs-content {
                .units-buttons {
                    margin-bottom: 30px;
                }

                .specs-wrapper {
                    @include grid(16, var(--gutter-width));

                    @media screen and (max-width: $sm-max) {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 30px;
                    }

                    table {
                        @include col(8);
                        width: 100%;

                        @media screen and (max-width: $xs-max) {
                            display: block;
                            overflow-x: auto;
                            max-width: calc(100vw - (var(--gutter-width) * 2));

                            tbody {
                                white-space: nowrap;
                            }
                        }

                        td {
                            height: 80px;
                            padding: 20px 30px;
                            border-bottom: 1px solid $light-grey;
                            border-right: 1px solid $light-grey;

                            @media screen and (max-width: $xs-max) {
                                padding: 10px 20px;
                                height: 60px;
                            }

                            &.letter {
                                background-color: $light-grey;
                                text-align: center;
                            }

                            &:first-child {
                                width: calc(100% / 3);
                            }

                            &.hidden {
                                display: none;
                            }
                        }
                    }

                    .image-frame {
                        @include col(8);
                        position: sticky;
                        top: calc(var(--headerHeight) + 10px);

                        @media screen and (max-width: $sm-max) {
                            position: relative;
                            top: 0;
                        }

                        &:first-child {
                            @include col(16);
                        }
                    }
                }
            }

            &.installation-content {
                .pagebuilder-component-installation-maintenance {
                    margin-top: 30px;

                    .centered-content {
                        width: 100%;
                    }
                }
            }
        }
    }
}
