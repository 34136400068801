// @font-face definitions

@font-face {
    font-family: 'Titillium Web';
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
        url('../fonts/TitilliumWeb-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium Web';
    src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
        url('../fonts/TitilliumWeb-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Titillium Web';
    src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
        url('../fonts/TitilliumWeb-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
