@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

.content-with-sidebar {
    // if last child before footer we add a margin
    &:nth-last-child(2) {
        margin-bottom: vw(110px, false, 70px);
    }

    .centered-content {
        aside {
            position: sticky;
            z-index: 9;
            top: 100px;
            margin-bottom: 40px;
            margin-top: 0;
            @include col(4);
            @include col-start(1);

            .cta-link {
                font-weight: 400;
            }
        }

        .content {
            @include col-start(5);
            @include col(8);

            *:first-child {
                margin-top: 0;
            }

            .centered-content {
                width: 100%;
                margin-left: 0;
                margin-right: 0;

                .section-title {
                    h5,
                    .like-h5 {
                        text-transform: none;
                    }
                }
            }
        }

        @media only screen and (max-width: $sm-max) {
            .content {
                @include col-start(5);
                @include col(12);
            }
        }

        @media only screen and (max-width: $xs-max) {
            aside {
                top: 70px;
                margin-bottom: 40px;
                @include col(2);
                @include col-start(1);

                .graphic-text:nth-child(1),
                ul {
                    display: none;
                }
                .graphic-text {
                    display: none;
                }

                .shares {
                    a {
                        margin-bottom: 5px;
                        max-width: 100%;

                        span{
                            max-width: 100%;
                        }
                    }
                }
            }

            .content {
                @include col-start(3);
                @include col(14);
            }
        }
    }
}
