@import '../../config';
@import '../../mixins';
@import '../../functions';

#overlay-check-compatibility {
    .back-btn {
        position: absolute;
        z-index: 3;
        top: 40px;
        left: 30px;
        cursor: pointer;

        @media only screen and (max-width: $xs-max) {
            top: 5px;
            left: calc(var(--gutter-width) * 2);
        }

        svg {
            width: 31px;
            height: 29px;
            transform: rotate(180deg);
        }

        &.hide {
            display: none;
        }
    }

    .product-info {
        margin: 100px 50px 0px;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 10px;
        background-color: $lighter-grey;
        border-radius: 10px;

        @media screen and (max-width: $sm-max) {
            margin: 80px 30px 0;
        }

        @media screen and (max-width: $xs-max) {
            margin: 50px 16px 0;
        }

        img {
            height: 65px;
            width: 65px;
        }

        p {
            margin: 0;

            &:first-child {
                margin-bottom: 5px;
            }
        }
    }

    .content {
        padding-bottom: 100px;
    }

    .title {
        margin-bottom: 0;
    }

    .subtitle {
        margin: 50px 0 24px;
    }

    .form-fields-columns {
        display: flex;
        gap: 30px;
        margin-top: 10px;

        @media screen and (max-width: $md-max) {
            flex-direction: column;
            gap: 10px;
        }
    }

    select {
        transition: opacity 0.2s linear;

        &.loading {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .compatible,
    .not-compatible {
        margin-bottom: 24px;

        .title {
            color: $red;
            margin-bottom: 50px;
        }
    }
}
