@use '../../config' as *;
@use '../../mixins' as *;

.cta-link {
    @include titillium();

    font-size: 20px;
    line-height: 1.4;
    font-style: normal;
    font-weight: 600;
    color: var(--text-color);
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: all 0.2s ease;

    &:hover,
    &:focus,
    &.active {
        color: $red;
        text-decoration: none;
    }

    &.big {
        font-size: 24px;
        line-height: normal;
    }

    &.alt {
        color: $red;

        &:hover,
        &:focus,
        &.active {
            color: var(--text-color);
        }
    }

    &.no-underline {
        background-size: 0% 1px;

        &:hover,
        &:focus,
        &.active {
            color: var(--text-color);
            background-size: 100% 1px;
        }
    }

    &.with-icon {
        display: inline-flex;
        align-items: center;
        gap: 14px;

        span[class^='icon-'] svg {
            height: 28px;
            width: 28px;
        }

        &,
        svg path {
            transition: all 0.1s ease;
        }

        &:hover,
        &:focus,
        &.active {
            background-size: 0% 1px;
            color: $red;

            svg path {
                stroke: $red;
            }
        }
    }
}
