@use '../config' as *;
@use '../mixins' as *;
@use '../functions' as *;

.toolkit {
    .cols {
        @include grid(16, var(--gutter-width));

        .col {
            @include col(1);
            background: pink;
            opacity: 0.6;
            height: 80px;
        }
    }

    section {
        margin-bottom: 50px;

        &:first-child {
            margin-top: 100px;
        }

        .tk-title {
            border-bottom: 1px solid black;
            border-top: 1px solid black;
            margin: 20px 0;
            padding: 20px 0;
        }
    }
}
