@use 'config' as *;
@use 'mixins' as *;

.left {
    float: left;
}

.right {
    float: right;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.caps {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.text-hide {
    text-indent: -9999px;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

// Hide from both screenreaders and browsers: h5bp.com/u
.hide-all {
    display: none;
    visibility: hidden;
}

@media screen and (max-width: $sm-max) {
    .hidden-sm {
        display: none;
    }
}

@media screen and (max-width: $xs-max) {
    .hidden-xs {
        display: none;
    }
}

.clearfix {
    @include clearfix();
}
