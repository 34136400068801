@use 'config' as *;
@use 'mixins' as *;
@use 'functions' as *;

//
// Root styles
// -----------------------------------------------------------------------------

html {
    --column-amount: #{$columns-amount-lg};
    --gutter-width: #{$gutter-width-lg};

    // permet de fetch la valeur des breakpoints dans le config.js
    --lg-min: #{$lg-min};
    --md-max: #{$md-max};
    --md-min: #{$md-min};
    --sm-max: #{$sm-max};
    --sm-min: #{$sm-min};
    --xs-max: #{$xs-max};

    //permet le smooth scroll ce qui évite de devoir programmer un smooth scroll nous même sur un click d'anchor
    scroll-behavior: smooth;

    //Retire le smooth scroll lors des transitions de page avec barba JS pour pas qu'onv oit la page scrolltop lentement pendant la transition.
    &.in-transition {
        scroll-behavior: auto;
    }

    //classe utilisé lors d'ouverture d'overlays pour éviter le scroll sur le body et dans l'overlay en même temps.
    &.no-scroll {
        overflow: hidden;
    }

    @media only screen and (max-width: $md-max) {
        --column-amount: #{$columns-amount-md};
        --gutter-width: #{$gutter-width-md};
    }

    @media only screen and (max-width: $sm-max) {
        --column-amount: #{$columns-amount-sm};
        --gutter-width: #{$gutter-width-sm};
    }

    @media only screen and (max-width: $xs-max) {
        --column-amount: #{$columns-amount-xs};
        --gutter-width: #{$gutter-width-xs};
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

body {
    margin: 0;
    @include titillium();
    background-color: var(--background-color);
    color: var(--text-color);

    &.preload * {
        animation-duration: 0s !important;
        -webkit-animation-duration: 0s !important;
        transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
    }
}

//
// Heading styles
// -----------------------------------------------------------------------------

h1,
.like-h1 {
    margin-top: 0;
    font-size: vw(120px, false, 80px);
    font-weight: 700;
    line-height: 0.9;
    letter-spacing: -3px;
    text-transform: uppercase;

    &.for-hero {
        font-size: vw(150px, false, 80px);
        line-height: 0.96;

        /* Surtitres imbriqués dans les titres hero */
        .like-h3 {
            line-height: 1.8;
        }
    }

    @media screen and (max-width: $xs-max) {
        font-size: vw(58px, true, 40px, 58px);

        &.for-hero {
            font-size: vw(68px, true, 50px, 68px);

            /* Reduction surtitres imbriqués dans les titres hero */
            .like-h3 {
                font-size: vw(26px, true, 22px, 26px);
            }
        }
    }
}

h2,
.like-h2 {
    margin-top: 0;
    font-size: vw(60px, false, 50px);
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1.2px;

    @media screen and (max-width: $xs-max) {
        font-size: vw(36px, true, 30px, 36px);
    }
}

h3,
.like-h3 {
    margin-top: 0;
    font-size: vw(40px, false, 30px);
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: -0.8px;

    @media screen and (max-width: $xs-max) {
        font-size: vw(32px, true, 24px, 32px);
    }
}

h4,
.like-h4 {
    margin-top: 0;
    font-size: vw(32px, false, 20px);
    font-weight: 600;
    line-height: 1.1;

    @media screen and (max-width: $xs-max) {
        font-size: vw(24px, true, 20px, 24px);
    }
}

h5,
.like-h5,
h6 {
    margin-top: 0;
    font-size: vw(28px, false, 18px);
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (max-width: $xs-max) {
        font-size: vw(22px, true, 20px, 22px);
    }
}

//
// Text styles
// -----------------------------------------------------------------------------

p,
.like-p {
    @include titillium();
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0;
    margin: 20px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.small {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
    }
}

.text-highlight {
    font-size: vw(90px, false, 60px);
    font-weight: 700;
    line-height: 1;
    letter-spacing: -2px;

    @media screen and (max-width: $xs-max) {
        font-size: vw(46px, true, 38px, 46px);
    }
}

.graphic-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

//
// Link styles
// -----------------------------------------------------------------------------

a {
    @include titillium();
    color: $black;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: $black;
    }
}

section.dark {
    a {
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $white;
        }
    }
}

button {
    @include titillium();
}

//
// List styles
// -----------------------------------------------------------------------------

ul,
ol {
    padding: 0 0 0 18px;
    margin: 0;

    li {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}

ul {
    li::marker {
        color: $red;
    }
}

li {
}

//
// Misc styles
// -----------------------------------------------------------------------------

hr {
    border: none;
    border-bottom: 1px solid $light-grey;
    margin: 24px 0;
}

// Accordeons
details {
    summary {
        position: relative;
        list-style: none;
        user-select: none;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            background-color: $dark-grey;
        }

        &::before {
            height: 15px;
            width: 1px;
            right: 7px;
            transform-origin: center;
            transition: transform 0.2s ease;
        }

        &::-webkit-details-marker {
            display: none;
        }

        &::after {
            width: 15px;
            height: 1px;
        }
    }

    &[open] summary::before {
        transform: scale(0);
    }

    ul {
        list-style: none;
    }
}

.spinner-wrapper .loader {
    font-weight: 600;
}
