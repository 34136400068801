@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component-timeline {
    .centered-content {
        .text-wrapper {
            @include col-start(1);
            @include col(var(--column-amount));

            .suptitle {
                margin-block: 0;
            }

            .title {
                margin-block: 10px 15px;
            }

            .description {
                margin-block: 0 50px;
            }
        }
    }

    .timeline-content {
        @include grid(var(--column-amount), var(--gutter-width));
        min-height: 675px; // force height because images are absolutes

        .images-wrapper {
            position: relative;

            .images {
                position: absolute;
                top: 0;
                z-index: 0;
                // The width property value mimics a grid column layout as this element is absolute. The number 8 represents the grid-column-end value.
                width: calc(((100% - (var(--gutter-width) * 4)) / var(--column-amount)) * 8);
                left: 0;
                max-height: 675px;
                aspect-ratio: 1;
                overflow: hidden;
                border-radius: 10px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    display: block;
                    opacity: 0;
                    transform: scale(1.1);
                    transition: all 0.64s ease;

                    &.active {
                        opacity: 1;
                        transform: scale(1);
                        left: 0;
                    }
                }
            }
        }

        .content {
            @include grid(var(--column-amount), var(--gutter-width));
            position: relative;
            z-index: 2;
            align-content: start;
            height: calc(675px - 75px); // 75px is margin-top of year in image
        }

        .dates-wrapper {
            @include col-start(8);
            @include col(9);
            position: relative;
            margin-bottom: 75px;

            .dates {
                width: max-content;
                display: flex;
                align-items: flex-end;
                column-gap: 18px;

                .date {
                    margin: 0;
                    line-height: 1;
                    position: relative;
                    float: left;
                    font-weight: 700;
                    transition: all 0.6s ease-out, font-size 0.45s ease, line-height 0.45s ease, top 0.45s ease,
                        opacity 0.5s $ease-in-out-quad;

                    &.text-highlight {
                        line-height: 0.9;
                    }

                    &.hidden {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }

        .details {
            @include col-start(10);
            @include col(6);
            position: relative;
            align-self: end;
            margin: 0;

            .detail {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: all 0.3s ease;
                pointer-events: none;

                &.active {
                    pointer-events: auto;
                }

                .title {
                    margin-block: 0 30px;
                }

                .description {
                    p {
                        margin: 0;
                    }
                }
            }
        }

        .controls {
            @include col-start(8);
            @include col(9);
            display: inline-flex;
            justify-content: flex-end;
            column-gap: 15px;
            margin-bottom: 50px;

            .arrow-controls {
                cursor: pointer;
                transition: opacity 0.2s ease;

                &:disabled {
                    opacity: 0.4;
                    pointer-events: none;
                }

                svg {
                    height: 45px;
                    width: 50px;

                    @media only screen and (max-width: $xs-max) {
                        height: 35px;
                        width: 40px;
                    }

                    g {
                        stroke: $white;
                    }
                }
            }

            .previous {
                transform-origin: center;
                transform: rotate(-180deg);
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content {
            min-height: unset;
        }

        .timeline-content {
            overscroll-behavior-x: none;
            min-height: unset;

            .images-wrapper {
                .images {
                    width: 100%;
                    position: relative;
                }
            }

            .content {
                height: unset;
                margin: -40px 0 0;
                row-gap: 25px;
            }

            .dates-wrapper {
                order: 1;
                @include col-start(1);
                @include col(var(--column-amount));
                margin-bottom: 0;

                .dates {
                    gap: 25px;
                }
            }

            .details {
                order: 3;
                @include col-start(1);
                @include col(var(--column-amount));
                min-height: 0;

                .detail {
                    .title {
                        margin: 0 0 15px;
                    }
                }
            }

            .controls {
                order: 2;
                @include col-start(1);
                @include col(var(--column-amount));
                justify-content: flex-start;
                margin-bottom: 10px;
                column-gap: 10px;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            .text-wrapper {
                .title {
                    margin-block: 5px 10px;
                }

                .description {
                    margin-block: 0 30px;
                }
            }
        }
        .timeline-content {
            .content {
                margin: -25px 0 0;
                row-gap: 25px;
            }
        }
    }
}
