@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

section,
.section {
    background-color: var(--background-color);
    color: var(--text-color);

    &.no-margin {
        margin: 0;
    }

    &:not(.no-margin) {
        margin: vw(110px, false, 70px) 0;

        &.dark {
            margin: 0;
            padding: vw(110px, false, 70px) 0;

            & + .dark {
                margin-top: -1px;
                padding: 0 0 vw(110px, false, 70px) 0;
            }
        }

        &.gray {
            margin: 0;
            padding: vw(110px, false, 70px) 0;

            & + .gray {
                margin-top: -1px;
                padding: 0 0 vw(110px, false, 70px) 0;
            }
        }

        &.article {
            margin: 40px 0;

            &.dark {
                margin: 0;
                padding: 40px 0;

                & + .dark {
                    margin-top: -1px;
                    padding: 0 0 40px 0;
                }
            }

            &.gray {
                margin: 0;
                padding: 40px 0;

                & + .gray {
                    margin-top: -1px;
                    padding: 0 0 40px 0;
                }
            }
        }
    }

    @media screen and (max-width: $sm-max) {
        &:not(.no-margin) {
            margin: vw(70px, false, 50px) 0;

            &.dark {
                margin: 0;
                padding: vw(70px, false, 50px) 0;

                & + .dark {
                    padding: 0 0 vw(70px, false, 50px) 0;
                }
            }

            &.gray {
                margin: 0;
                padding: vw(70px, false, 50px) 0;

                & + .gray {
                    padding: 0 0 vw(70px, false, 50px) 0;
                }
            }
        }
    }

    &.dark {
        --background-color: #{$dark-grey};
        --text-color: #{$white};
    }

    &.gray {
        --background-color: #{$lighter-grey};
        --text-color: #{$dark-grey};
    }

    &.light {
        --background-color: #{$white};
        --text-color: #{$dark-grey};
    }

    .centered-content {
        position: relative;
        width: $container-lg;
        margin: 0 auto;

        @include grid(var(--column-amount), var(--gutter-width));
    }

    @media only screen and (max-width: $md-max) {
        .centered-content {
            width: $container-md;
        }
    }

    @media only screen and (max-width: $sm-max) {
        .centered-content {
            width: $container-sm;
        }
    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            width: $container-xs;
            box-sizing: border-box;
        }
    }
}
