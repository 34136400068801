@import '../../config';
@import '../../mixins';
@import '../../functions';

.overlay {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh; /* fallback */
    height: 100dvh; /* Dynamic Viewport Height: with or without browser toolbars */
    pointer-events: none;
    right: -60vw;
    transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out;

    &.title-is-sticky {
        .sticky-title {
            opacity: 1;
            display: block;
        }

        .wrapper {
            .close {
                position: fixed;
                top: 15px;
                right: 15px;

                svg {
                    * {
                        stroke: $dark-grey;
                    }
                }
            }
        }
    }

    &.active {
        right: 0;
        pointer-events: auto;

        .backdrop {
            opacity: 0.25;
        }
    }

    .backdrop {
        content: '';
        position: fixed;
        inset: 0;
        opacity: 0;
        background-color: $dark-grey;
        transition: all 0.4s ease;
    }

    .sticky-title {
        position: fixed;
        display: none;
        z-index: 1;
        top: 0;
        right: 0;
        width: 50vw;
        box-sizing: border-box;
        opacity: 0;
        margin: 0;
        transition: opacity 0.3s ease;
        padding: 25px 90px 25px 40px;
        border-bottom: 1px solid $light-grey;
        background-color: $white;
    }

    .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 50vw;
        max-width: 880px;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        float: right;
        padding: 0;
        background-color: $white;

        .like-h5 {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px 30px;
        }

        .content {
            position: relative;
            z-index: 1;
            padding: 50px;
            display: block;
            margin: auto 0;
        }

        .close {
            position: absolute;
            z-index: 3;
            top: 40px;
            right: 30px;
            cursor: pointer;

            svg {
                height: 40px;

                * {
                    transition: stroke-width 0.2s ease;
                }
            }

            &:hover {
                svg {
                    * {
                        stroke-width: 2px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        right: -80vw;

        .sticky-title {
            width: 60vw;
            padding-left: 22px;
        }
        .wrapper {
            width: 60vw;

            .img-wrapper {
                aspect-ratio: unset !important;
            }

            .content {
                padding: 30px;
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        right: -110vw;

        .sticky-title {
            width: 100vw;
        }

        &.title-is-sticky {
            .wrapper {
                .close {
                    top: 10px;
                    right: 10px;
                }
            }
        }

        .wrapper {
            width: 100vw;

            .close {
                top: 5px;
                right: calc(var(--gutter-width) * 2);
            }

            .content {
                padding: 30px 16px;
            }
        }
    }
}
