@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.hero {
    display: flex;
    align-items: flex-end;
    position: relative;
    min-height: min(20vw, 300px);

    &.for-articles {
        h1 {
            text-transform: none;
            span {
                margin-bottom: 8px;
            }
        }

        .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 30px;
        }

        &.with-image {
            margin-bottom: vw(110px, false, 70px);

            @media screen and (max-width: $sm-max) {
                margin-bottom: vw(70px, false, 40px);
            }
        }
    }

    &.with-image {
        min-height: calc(100vw / (1792 / 760));
        color: $white;

        &.has-subnav-below {
            margin-bottom: -68px;

            @media screen and (max-width: $sm-max) {
                margin-bottom: -66px;
            }

            @media screen and (max-width: $xs-max) {
                margin-bottom: -118px;
            }

            /* This is used to have a background behind the sticky listing bar so we don't have a white margin */
            &::after {
                content: '';
                position: absolute;
                bottom: -50px;
                left: 0;
                width: 100%;
                height: 50px;
                background-color: $lighter-grey;
            }
        }
    }

    &.for-error-page {
        min-height: 100vh;

        h1 {
            font-size: vw(100px, false, 80px);
            line-height: 1;
        }
    }

    &.small {
        min-height: calc(100vw / (1792 / 433));

        h1 {
            text-transform: none;
        }

        .for-hero {
            font-size: vw(90px, false, 60px);
            font-weight: 700;
            line-height: 0.9;
            letter-spacing: -1.8px;
        }
    }

    @media only screen and (max-width: $sm-max) {
        height: auto !important;
    }

    .image-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            height: 100%;
        }

        @media only screen and (max-width: $xs-max) {
            img {
                max-width: none;
            }
        }
    }

    .centered-content {
        height: 100%;
    }

    .wrapper {
        box-sizing: border-box;
        height: 100%;
        padding-top: 120px;
        padding-bottom: 72px;
        @include grid(16, var(--gutter-width));
        align-items: flex-end;

        .content {
            @include col(10);
            @include col-start(5);

            @media only screen and (max-width: $sm-max) {
                @include col(12);
                @include col-start(2);
            }

            @media only screen and (max-width: $xs-max) {
                @include col(16);
                @include col-start(1);
            }
        }
        h1 {
            margin: 0;

            span {
                text-transform: none;
                display: block;
            }

            @media only screen and (max-width: $sm-max) {
                @include col-start(1);
            }
        }

        p {
            margin-top: 30px;
            @include property-calc-col(max-width, 10, 16, var(--gutter-width));

            @media only screen and (max-width: $sm-max) {
                max-width: none;
            }
        }
    }

    .background-filter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    }
}
