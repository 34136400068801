@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

label.custom-checkbox{
    position: relative;
    padding-left: 24px;
    cursor: pointer;

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        border-color: $dark-grey;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        border-color: $dark-grey;

        svg{
            opacity:1;
        }
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        width: 14px;
        height: 14px;
        border: 1px solid $light-grey;
        border-radius: 3px;

        svg{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            height: 8px;
            width: 10px;
            opacity:0;
        }

    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}
